import React from "react"
import "./style.css"

const OrangeButton = ({ show = true, children, extraClass, ...props }) => {
  if (!show) {
    return <></>
  }

  return (
    <button className={`blbtn-wrapper ${extraClass}`} {...props} tabIndex="0">
      {children}
    </button>
  )
}

export { OrangeButton }
