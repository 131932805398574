import api from '../api';
import { axiosGetRetry } from '../utils';

import {ON_GET_ORDER_TYPES_SUCCESS, ON_GET_ORDER_TYPES_FAILURE } from './types';

export const fetchOrderTypes = () => {
    return (dispatch) => {
        axiosGetRetry(api.getOrderTypes)
        .then(res => {
          dispatch({
            type: ON_GET_ORDER_TYPES_SUCCESS,
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({
            type: ON_GET_ORDER_TYPES_FAILURE,
            payload: err,
          });
          console.log('Failed to fetch order types select options');
          console.log(err);
        });
    }
  }