import React from "react"
import Modal from "react-modal"
import Loader from "../../common/Loader"
import "./style.css"

const LoaderModal = ({
  show,
  title,
  text,
  
}) => {
  if (!show) {
    return <noscript />
  }

  return (
    <Modal
      isOpen={show}
      className="react-modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="timer-modal-title">{title}</div>

      <div className="timer-modal-sub-title">{text}</div>
      
      <div className="loader-mb">
        <Loader />
      </div>
     
    </Modal>
  )
}

export default LoaderModal
