//SET_LOADING_COUPON, FETCH_COUPON_BY_NAME_SUCCESS, FETCH_COUPON_BY_NAME_FAIL, RESET_COUPONS_REDUCER
import axios from "axios"
import api from "../api"

import { axiosPutRetry, axiosGetRetry } from "../utils"
import {
  SET_LOADING_COUPON,
  FETCH_COUPON_BY_NAME_SUCCESS,
  FETCH_COUPON_BY_NAME_FAIL,
  RESET_COUPONS_REDUCER,
  ON_ADD_COUPON_TO_ORDER_SUCCESS,
  ON_ADD_COUPON_TO_ORDER_FAIL,
  ON_LOADING,
} from "./types"

import { reportFBEvent } from "./FBEventsActions"

export const fetchCouponByName = (couponName, orderType) => {
  return (dispatch) => {
    axiosGetRetry(api.fetchCouponByName(couponName, orderType))
      .then((res) => {
        dispatch({
          type: FETCH_COUPON_BY_NAME_SUCCESS,
          payload: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: FETCH_COUPON_BY_NAME_FAIL,
          payload: err,
        })
      })
  }
}

export const resetCoupons = () => {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_COUPON,
    })
  }
}

export const setCouponLoading = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_COUPONS_REDUCER,
    })
  }
}

export const addCouponByNameToOrder = (orderId, couponName) => {
  return (dispatch) => {
    dispatch({ type: ON_LOADING })

    axiosGetRetry(api.addCouponByNameToOrder(orderId, couponName))
      .then((res) => {
        dispatch({
          type: ON_ADD_COUPON_TO_ORDER_SUCCESS,
          payload: res.data,
        })
        dispatch(
          reportFBEvent(
            "addCouponByNameToOrder",
            "On pay add coupon by name to order."
          )
        )
      })
      .catch((err) => {
        dispatch({
          type: ON_ADD_COUPON_TO_ORDER_FAIL,
          payload: err,
        })
        console.log(err)
      })
  }
}
