import React, { Component } from "react"
import { connect } from "react-redux"
import {
  fetchStationServiceTypes,
  logScreenEnterance,
  readStationIdFromBgService,
  LogoutUser,
} from "../../../actions"
import Loader from "../../common/Loader"
import "./style.css"

class MaintenanceScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reqInterval: 0,
      reqInterval2: 0,
    }
  }

  componentDidMount() {
    const reqInterval = setInterval(
      () => this.props.readStationIdFromBgService(),
      3000
    )
    this.setState({ reqInterval })

    this.props.logScreenEnterance("MAINTENANCE_SCREEN")
  }

  componentWillUnmount() {
    const { reqInterval, reqInterval2 } = this.state

    if (reqInterval) {
      clearInterval(reqInterval)
    }
    if (reqInterval2) {
      clearInterval(reqInterval2)
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      stationId,
      isDeliveryActive,
      isLaundryActive,
      isLockerRentActive,
      isLockerToLockerDeliveryActive,
    } = newProps
    const { reqInterval, reqInterval2 } = this.state
    if (
      stationId !== -1 &&
      !this.isStationHaveAnyType(
        isDeliveryActive,
        isLaundryActive,
        isLockerRentActive,
        isLockerToLockerDeliveryActive
      )
    ) {
      if (!reqInterval2) {
        const reqInterval2 = setInterval(
          () => this.props.fetchStationServiceTypes(stationId),
          4000
        )
        this.setState({ reqInterval2 })
        console.log("set reqInterval2:" + reqInterval2)
      }

      //this.props.fetchStationServiceTypes(stationId);
    }

    if (
      stationId !== -1 &&
      this.isStationHaveAnyType(
        isDeliveryActive,
        isLaundryActive,
        isLockerRentActive,
        isLockerToLockerDeliveryActive
      )
    ) {
      this.props.LogoutUser()
      this.props.history.push("")
    }
  }

  isStationHaveAnyType(
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive
  ) {
    const isAnyType =
      isDeliveryActive ||
      isLaundryActive ||
      isLockerRentActive ||
      isLockerToLockerDeliveryActive
    console.log("Station have types:" + isAnyType)
    //console.log('isDeliveryActive:'+isDeliveryActive);
    //console.log('isLaundryActive:'+isLaundryActive);
    //console.log('isDechatlon:'+ null);
    return isAnyType
  }

  render() {
    return (
      <div className="maint-wrapper">
        <div className="maint-msg">
          ,אנא המתן <br />
          המערכת תעלה תוך מספר רגעים
          <div className="loader-wrapper">
            <Loader />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ bgService, stationServiceTypes }) => {
  const { stationId } = bgService
  const {
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
  } = stationServiceTypes
  return {
    stationId,
    isDeliveryActive,
    isLaundryActive,
    isLockerRentActive,
    isLockerToLockerDeliveryActive,
  }
}

export default connect(mapStateToProps, {
  readStationIdFromBgService,
  logScreenEnterance,
  fetchStationServiceTypes,
  LogoutUser,
})(MaintenanceScreen)
