import api from "../api"
import { axiosGetRetry } from "../utils"

import {
  FETCH_ALL_USER_ACTIVE_ORDERS_SUCCESS,
  FETCH_ALL_USER_ACTIVE_ORDERS_FAIL,
  SET_RENTAL_ORDER,
  ON_LOADING,
} from "./types"

export const setRentalOrder = (order) => {
  return {
    type: SET_RENTAL_ORDER,
    payload: order,
  }
}

export const fetchUserActiveOrders = (userId, stationId, isRent) => {
  return (dispatch) => {
    dispatch({ type: ON_LOADING })

    axiosGetRetry(api.getAllUserActiveOrders(userId, stationId))
      .then((res) => {
        const notRentOrders = res.data.filter((order) => order.orderType !== 4)
        const rentOrders = res.data.filter((order) => order.orderType === 4)
        dispatch({
          type: FETCH_ALL_USER_ACTIVE_ORDERS_SUCCESS,
          payload: isRent
            ? { ordersList: rentOrders, isRent }
            : { ordersList: notRentOrders },
        })
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_USER_ACTIVE_ORDERS_FAIL,
          payload: err,
        })
      })
  }
}
